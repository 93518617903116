<template>
	<Popup
		:visible.sync="localVisible"
		@closePopup="$emit('closePopup')"
	>
		<PaymentMethod
			class="payment-method-popup"
			@closePopup="$emit('closePopup')"
		/>
	</Popup>
</template>
<script>
export default {
	name: "PaymentMethodPopup",
	components: {
		PaymentMethod: () => import("@/components/block/payment-method/PaymentMethod.vue")
	},
	props: {
		visible: {
			type: Boolean,
			default: false
		}
	},
	data()
	{
		return {
			localVisible: false,
		};
	},
	watch: {
		visible()
		{
			this.localVisible = this.visible;
		}
	}
}
</script>
<style
	lang="scss"
>
.payment-method-popup
{
	padding: 32px 40px;
	width: 520px;

	@media (max-width: 990px)
	{
		width: auto;
	}
}
</style>